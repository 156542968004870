import * as firebase from "firebase";
import "firebase/database";

let config = {
    apiKey: "AIzaSyDHmYHRt21rxPOfTri1-NC2X6h9NOqRE98",
    authDomain: "amor937-149e6.firebaseapp.com",
    databaseURL: "https://amor937-149e6-default-rtdb.firebaseio.com",
    projectId: "amor937-149e6",
    storageBucket: "amor937-149e6.appspot.com",
    messagingSenderId: "565046548070",
    appId: "1:565046548070:web:ac3e7fb11f419edfac3fa7",
    measurementId: "G-0XJKCZBRVB"
};

firebase.initializeApp(config);

export default firebase.database();